import { Component, OnInit } from '@angular/core';
import { Actu } from '../_models/actu';
import { ActuService } from '../_services/actu.service';
import { MetadataServiceService } from '../_services/metadata-service.service';

@Component({
  selector: 'app-actualites',
  templateUrl: './actualites.component.html',
  styleUrls: ['./actualites.component.scss']
})
export class ActualitesComponent implements OnInit {

  infoBandeau = {
    'image' : './../../assets/bandeau-actualites.jpg',
    'titre' : "ACTUALITÉS de l'Association Le Patis Fraux",
    'breadcrumb' : 'ACCUEIL > Actualités'
  }

  actualites : Actu[] = [];
  nbActu : number = 0;

  constructor(private actuService: ActuService, private metadataService: MetadataServiceService) {
    this.actuService.getActus().subscribe(
      (actualites) => {
        this.actualites = actualites;
        this.nbActu = this.actualites.length;
      }
    );

    this.metadataService.updateMetadata({
      title: 'Le Patis Fraux - ' + this.infoBandeau.titre,
      description: 'Liste des actualités'
    });
  }

  ngOnInit(): void {
  }

}
