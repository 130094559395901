import { Component, OnInit } from '@angular/core';
import { Doc } from '../_models/doc';
import { DocService } from '../_services/doc.service';
import { MetadataServiceService } from '../_services/metadata-service.service';

@Component({
  selector: 'app-formation-prestation',
  templateUrl: './formation-prestation.component.html',
  styleUrls: ['./formation-prestation.component.scss']
})
export class FormationPrestationComponent implements OnInit {

  infoBandeau = {
    'image' : './../../assets/bandeau-formations.jpg',
    'titre' : 'Formations',
    'breadcrumb' : 'ACCUEIL > Formations'
  }

  docPresta : Doc[] = [];
  docFormation : Doc[] = [];
  isExpanded: boolean = false;
  expandedFormation: { titre: string, texte: string, document: string } = { titre: '', texte: '', document: "" };

  prap2S: {titre: string, texte: string, document: string, isExpanded: boolean} = {
    titre: 'PRAP Sanitaire et Médico-Social (2S)',
    texte: "La formation PRAP 2S est à destination de tous les salariés, opérateurs et agents du secteur sanitaire et médico-social (établissements de santé, structures d’aides et de soins à la personne, EHPAD...). <br><br> La formation PRAP 2S intègre la démarche d’Accompagnement à la Mobilité (ALM). Elle permet d’accompagner la mobilité de la personne aidée en prenant soin de l’autre et de soi. Cette démarche vise à prévenir l’exposition au risque de troubles musculosquelettiques (TMS) et aux chutes liées au portage des patients. Pour en savoir plus sur la démarche (ALM) (lien vers PDF)",
    document: "formation-PRAP-2S.pdf",
    isExpanded: false
  }
  macprap2S: {titre: string, texte: string, document: string, isExpanded: boolean} = {
    titre: 'Formation MAC PRAP 2S',
    texte: "La formation MAC PRAP 2S est à réaliser tous les 2 ans après la formation acteur PRAP 2S afin que le certificat d’acteur PRAP 2S puisse rester valide. Elle permet à tout professionnel de continuer d’être un acteur de prévention et d’être force de proposition en matière d’amélioration des risques professionnels.",
    document: "formation-MAC-PRAP-2S.pdf",
    isExpanded: false
  }
  prapIBC: {titre: string, texte: string, document: string, isExpanded: boolean} = {
    titre: 'PRAP Industrie Bâtiment travaux publics Commerce (IBC)',
    texte: "La formation PRAP IBC s’adresse à tous les salariés, opérateurs et agents d’une entreprise appartenant au secteur Industrie-Bâtiment-Commerce, et activités de bureau.",
    document: "formation-PRAP-IBC.pdf",
    isExpanded: false
  }
  macprapIBC: {titre: string, texte: string, document: string, isExpanded: boolean} = {
    titre: 'Formation MAC PRAP IBC',
    texte: "La formation MAC PRAP IBC est à réaliser tous les 2 ans après la formation acteur PRAP IBC afin que le certificat d’acteur PRAP IBC puisse rester valide. Elle permet à tout professionnel de continuer d’être un acteur de prévention et d’être force de proposition en matière d’amélioration des risques professionnels.",
    document: "formation-MAC-PRAP-IBC.pdf",
    isExpanded: false
  }


  constructor(private docService : DocService, private metadataService: MetadataServiceService) {
    this.docService.getDocsByCat('catalogue-prestation').subscribe(
      (docPresta) => {
        this.docPresta = docPresta
      }
    );
    this.docService.getDocsByCat('catalogue-formation').subscribe(
      (docFormation) => {
        this.docFormation = docFormation
      }
    );
  }

  ngOnInit(): void {
    this.metadataService.updateMetadata({
      title: 'Le Patis Fraux - ' + this.infoBandeau.titre,
      description: "Liste des formations de l'Association Le Patis Fraux"
    });
  }

  assignerContenu(formation: string): void {
    switch (formation) {
      case "PRAP2S":
        this.prap2S.isExpanded = !this.prap2S.isExpanded;
        this.expandedFormation.document = this.prap2S.document
        break;
      case "MACPRAP2S":
        this.macprap2S.isExpanded = !this.macprap2S.isExpanded;
        this.expandedFormation.document = this.macprap2S.document
        break;
      case "PRAPIBC":
        this.prapIBC.isExpanded = !this.prapIBC.isExpanded;
        this.expandedFormation.document = this.prapIBC.document
        break;
      case "MACPRAPIBC":
        this.macprapIBC.isExpanded = !this.macprapIBC.isExpanded;
        this.expandedFormation.document = this.macprapIBC.document
        break;

      default:
        break;
    }
  }
}
